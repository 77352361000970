/**!
 * --------------------------------------------------------------------------
 * Bootstrap (v5.0.0-alpha3): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

const NODE_TEXT = 3

const SelectorEngine = {
  matches(element, selector) {
    return element.matches(selector)
  },

  find(selector, element = document.documentElement) {
    return [].concat(...Element.prototype.querySelectorAll.call(element, selector))
  },

  findOne(selector, element = document.documentElement) {
    return Element.prototype.querySelector.call(element, selector)
  },

  children(element, selector) {
    const children = [].concat(...element.children)

    return children.filter(child => child.matches(selector))
  },

  parents(element, selector) {
    const parents = []

    let ancestor = element.parentNode

    while (ancestor && ancestor.nodeType === Node.ELEMENT_NODE && ancestor.nodeType !== NODE_TEXT) {
      if (this.matches(ancestor, selector)) {
        parents.push(ancestor)
      }

      ancestor = ancestor.parentNode
    }

    return parents
  },

  prev(element, selector) {
    let previous = element.previousElementSibling

    while (previous) {
      if (previous.matches(selector)) {
        return [previous]
      }

      previous = previous.previousElementSibling
    }

    return []
  },

  next(element, selector) {
    let next = element.nextElementSibling

    while (next) {
      if (this.matches(next, selector)) {
        return [next]
      }

      next = next.nextElementSibling
    }

    return []
  },

  setValue(element, val) {
    if (element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
      element.value = val
    }
    else {
      element.innerText = val
    }
  },

  getValue(element) {
    if (Array.isArray(element)) {
      for (var i = 0; i < element.length; i++) {
        if (element[i].tagName === 'INPUT') {
          if (element[i].checked) {
            return element[i].value;
          }
        }
      }
    }
    if (element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
      return element.value
    }
    else {
      return element.innerText
    }

  },

  addClass(element, class_name) {
    element.classList.add(class_name)
  },

  removeClass(element, class_name) {
    element.classList.remove(class_name)
  }


}

export default SelectorEngine
