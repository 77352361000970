/**!
 * GAMA v1.0alpha0 (https://holding.gama.com.tr/)
 * Copyright 2020 GAMA Holding A.Ş.
 * UNLICENSED
 */

const AjaxHelper = {
  fetch(data) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState !== 4) return;
      if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
        return JSON.parse(xhr.response)
      }
      else {
        return new Error('Ajax Fetch Error' + xhr.response)
      }
    }
    xhr.open("POST", data.url, true);
    xhr.send(data);
  }
}

export default AjaxHelper