/**!
 * GAMA v1.0alpha0 (https://holding.gama.com.tr/)
 * Copyright 2020 GAMA Holding A.Ş.
 * UNLICENSED
 */

import {
  typeCheckConfig
} from '../dom/util'
import AjaxHelper from '../dom/ajax-helper'

const NAME = 'sayax'
const VERSION = '1.0-alpha0'

const Default = {
  params: {},
}

const DefaultType = {
  params: 'object',
}

class Sayax {
  constructor(params) {
    this._params = this._getConfig(params)
  }

  //getters
  static get VERSION() {
    return VERSION
  }

  //Public
  WSULogin() {
    var data = new FormData()
    data.append("action", "sayax_wsulogin")
    data.append("login", this._params.username)
    data.append("password", this._params.password)
    data.append("url", this._params.url)
    return AjaxHelper.fetch(data)
  }

  getCustomerInformation() {
    var data = new FormData()
    data.append("action", "sayax_musteribilgi");
    data.append("WSUToken", this._params.WSUToken);
    data.append("url", this._params.url)
    return AjaxHelper.fetch(data)
  }

  getCustomerInvoices() {
    var data = new FormData()
    data.append("action", "sayax_faturabilgi");
    data.append("WSUToken", this._params.WSUToken);
    data.append("url", this._params.url)
    return AjaxHelper.fetch(data)
  }

  getCustomerMeters() {
    var data = new FormData()
    data.append("action", "sayax_sayacbilgi");
    data.append("WSUToken", this._params.WSUToken);
    data.append("url", this._params.url)
    return AjaxHelper.fetch(data)
  }



  // Private
  _getConfig(config) {
    config = {
      ...Default,
      ...config
    }
    typeCheckConfig(NAME, config, DefaultType)
    return config
  }

  _btoaUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
  }

  _atobUnicode(str) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  _setCookie(name, value) {
    sessionStorage.setItem(name, value);
  }

  _deleteCookie(name) {
    sessionStorage.removeItem(name);
  }

  _getCookie(name) {
    return sessionStorage.getItem(name);
  }


}

export default Sayax