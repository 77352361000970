/**!
 * GAMA v1.0alpha0 (https://holding.gama.com.tr/)
 * Copyright 2020 GAMA Holding A.Ş.
 * UNLICENSED
 * ---
 * --------------------------------------------------------------------------
 * Bootstrap (v5.0.0-alpha3): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Modal from './js/modules/modal'
import Calculator from './js/modules/calculator'
import Carousel from './js/modules/carousel'
import Sidepanel from './js/modules/sidepanel'
import Menu from './js/modules/menu'
import Reveal from './js/modules/reveal'
import Sayax from './js/modules/sayax'
import Collapse from './js/modules/collapse'

export default {
  Modal,
  Calculator,
  Carousel,
  Sidepanel,
  Menu,
  Reveal,
  Sayax,
  Collapse
}
