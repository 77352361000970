/**!
 * GAMA v1.0alpha0 (https://holding.gama.com.tr/)
 * Copyright 2020 GAMA Holding A.Ş.
 * UNLICENSED
 */

import {
  getElementFromSelector,
  typeCheckConfig,
  isVisible
} from '../dom/util'
import Data from '../dom/data'
import EventHandler from '../dom/event-handler'
import Manipulator from '../dom/manipulator'
import SelectorEngine from '../dom/selector-engine'

const NAME = 'menu'
const VERSION = '1.0-alpha0'
const DATA_KEY = 'acl.menu'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_HIDE = `close${EVENT_KEY}`
const EVENT_HIDE_PREVENTED = `closePrevented${EVENT_KEY}`
const EVENT_HIDDEN = `closed${EVENT_KEY}`
const EVENT_SHOW = `open${EVENT_KEY}`
const EVENT_SHOWN = `opened${EVENT_KEY}`
const EVENT_CLICK_DISMISS = `click.dismiss${EVENT_KEY}`
const EVENT_MOUSEOVER = `mouseover${EVENT_KEY}`
const EVENT_MOUSEOUT = `mouseout${EVENT_KEY}`

const CLASS_NAME_OPEN = 'menu-open'
const CLASS_NAME_FADE = 'animate'
const CLASS_NAME_SHOW = 'subnav-open'
const CLASS_NAME_BACKDROP = 'modal-backdrop'

const SELECTOR_TOGGLE = '.nav-link'
//const SELECTOR_PANEL = '.mobile-menu'
const SELECTOR_PANEL_BODY = '.subnav-body'


const SELECTOR_DATA_TOGGLE = '[data-toggle="menu"]'


const Default = {
  backdrop: false,
  keyboard: false,
  focus: true,
  show: true
}

const DefaultType = {
  backdrop: '(boolean|string)',
  keyboard: 'boolean',
  focus: 'boolean',
  show: 'boolean'
}

const EVENT_OPEN_PANEL = `open${EVENT_KEY}`
const EVENT_MOUSEOVER_DATA_API = `mouseover${EVENT_KEY}${DATA_API_KEY}`

/*
    var overlay = document.getElementById("MenuOverlay");
 */


class Menu {
  constructor(element, config) {
    this._config = this._getConfig(config)
    this._isShown = false
    this._backdrop = null
    this._isTransitioning = false
    this._element = element
    Data.setData(element, DATA_KEY, this)
  }

  // Getters
  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  //Public
  show(relatedTarget) {
    let HasChildren = this._getHasChildren(relatedTarget)

    if (this._isTransitioning) {
      return
    }

    if (HasChildren === "false") {
      this.hide()
    }

    if (this._element.classList.contains(CLASS_NAME_FADE)) {
      this._isTransitioning = true
    }

    const showEvent = EventHandler.trigger(this._element, EVENT_SHOW, {
      relatedTarget
    })

    this._isShown = true


    this._showBackdrop(() => this._showElement(relatedTarget))
  }

  hide(event) {

    if (event) {
      event.preventDefault()
    }

    if (!this._isShown || this._isTransitioning) {
      return
    }

    const hideEvent = EventHandler.trigger(this._element, EVENT_HIDE)

    if (hideEvent.defaultPrevented) {
      return
    }

    this._isShown = false
    const transition = this._element.classList.contains(CLASS_NAME_FADE)

    if (transition) {
      this._isTransitioning = true
    }

    //this._setEscapeEvent()
    //this._setResizeEvent()

    //EventHandler.off(document, EVENT_FOCUSIN)
    this._element.classList.remove(CLASS_NAME_SHOW)

    EventHandler.off(this._element, EVENT_CLICK_DISMISS)
    //EventHandler.off(this._dialog, EVENT_MOUSEDOWN_DISMISS)

    if (transition) {
      const transitionDuration = getTransitionDurationFromElement(this._element)

      EventHandler.one(this._element, TRANSITION_END, event => this._hideModal(event))
      emulateTransitionEnd(this._element, transitionDuration)
    } else {
      this._hideModal()
    }
  }


  //Private
  _getConfig(config) {
    config = {
      ...Default,
      ...config
    }
    typeCheckConfig(NAME, config, DefaultType)
    return config
  }

  _fadeOut(el) {
    el.style.opacity = 1
      (function fade() {
        if ((el.style.opacity -= .1) < 0) {
          el.style.display = "none"
        } else {
          requestAnimationFrame(fade)
        }
      })
  }

  _fadeIn(el, display) {
    el.style.opacity = 0
    el.style.display = display || "block"
      (function fade() {
        var val = parseFloat(el.style.opacity)
        if (!((val += .1) > 1)) {
          el.style.opacity = val
          requestAnimationFrame(fade)
        }
      })
  }

  _getHasChildren(element) {
    return element.getAttribute('data-has-children')
  }


  _getTargetId(element) {
    return element.getAttribute('data-target-id')
  }

  _getTarget(element) {
    return element.getAttribute('data-target')
  }


  _showElement(relatedTarget) {
    const transition = this._element.classList.contains(CLASS_NAME_FADE)
    const modalBody = SelectorEngine.findOne(SELECTOR_PANEL_BODY, this._dialog)
    let Target = this._getTargetId(relatedTarget)
    let TargetId = this._getTargetId(relatedTarget)
    let HasChildren = this._getHasChildren(relatedTarget)

    if (HasChildren === "false") {
      this.hide()
      return
    }

    if (!this._element.parentNode ||
      this._element.parentNode.nodeType !== Node.ELEMENT_NODE) {
      // Don't move modal's DOM position
      document.body.appendChild(this._element)
    }

    this._element.style.display = 'block'
    this._element.removeAttribute('aria-hidden')
    this._element.setAttribute('aria-modal', true)
    this._element.setAttribute('role', 'dialog')
    this._element.scrollTop = 0

    if (modalBody) {
      modalBody.scrollTop = 0
    }

    if (transition) {
      reflow(this._element)
    }


    var subMenu = document.querySelectorAll("[id^=\"submenu-item-\"]")
    for (var i = 0; i < subMenu.length; i++) {
      if (!subMenu[i].classList.contains("hidden")) {
        subMenu[i].classList.add("hidden");
      }
    }

    var subMenuItem = document.querySelector("#submenu-item-" + TargetId)
    subMenuItem.classList.remove("hidden");

    this._element.classList.add(CLASS_NAME_SHOW)


    if (this._config.focus) {
      //  this._enforceFocus()
    }

    const transitionComplete = () => {
      if (this._config.focus) {
        this._element.focus()
      }

      this._isTransitioning = false
      EventHandler.trigger(this._element, EVENT_SHOWN, {
        relatedTarget
      })
    }

    if (transition) {
      const transitionDuration = getTransitionDurationFromElement(this._dialog)

      EventHandler.one(this._dialog, TRANSITION_END, transitionComplete)
      emulateTransitionEnd(this._dialog, transitionDuration)
    } else {
      transitionComplete()
    }
  }

  _hideModal() {
    if (this._element !== null) {
      this._element.setAttribute('aria-hidden', true)
      this._element.removeAttribute('aria-modal')
      this._element.removeAttribute('role')
    }
    this._isTransitioning = false
    this._showBackdrop(() => {
      document.body.classList.remove(CLASS_NAME_OPEN)
      EventHandler.trigger(this._element, EVENT_HIDDEN)
    })
  }

  _removeBackdrop() {
    this._backdrop.parentNode.removeChild(this._backdrop)
    this._backdrop = null
  }

  _showBackdrop(callback) {
    const animate = '';
    try {
      animate = this._element.classList.contains(CLASS_NAME_FADE) ?
        CLASS_NAME_FADE :
        ''
    }
    catch (e) {
      //
    }
    if (this._isShown && this._config.backdrop) {
      this._backdrop = document.createElement('div')
      this._backdrop.className = CLASS_NAME_BACKDROP

      if (animate) {
        this._backdrop.classList.add(animate)
      }

      document.body.appendChild(this._backdrop)

      EventHandler.on(this._backdrop, EVENT_CLICK_DISMISS, event => {
        if (this._ignoreBackdropClick) {
          this._ignoreBackdropClick = false
          return
        }

        if (event.target !== event.currentTarget) {
          return
        }

        this._triggerBackdropTransition()
      })

      if (animate) {
        reflow(this._backdrop)
      }

      this._backdrop.classList.add(CLASS_NAME_SHOW)

      if (!animate) {
        callback()
        return
      }

      const backdropTransitionDuration = getTransitionDurationFromElement(this._backdrop)

      EventHandler.one(this._backdrop, TRANSITION_END, callback)
      emulateTransitionEnd(this._backdrop, backdropTransitionDuration)
    } else if (!this._isShown && this._backdrop) {
      this._backdrop.classList.remove(CLASS_NAME_SHOW)

      const callbackRemove = () => {
        this._removeBackdrop()
        callback()
      }

      if (this._element.classList.contains(CLASS_NAME_FADE)) {
        const backdropTransitionDuration = getTransitionDurationFromElement(this._backdrop)
        EventHandler.one(this._backdrop, TRANSITION_END, callbackRemove)
        emulateTransitionEnd(this._backdrop, backdropTransitionDuration)
      } else {
        callbackRemove()
      }
    } else {
      callback()
    }
  }

  _triggerBackdropTransition() {
    if (this._config.backdrop === 'static') {
      const hideEvent = EventHandler.trigger(this._element, EVENT_HIDE_PREVENTED)
      if (hideEvent.defaultPrevented) {
        return
      }

      const isModalOverflowing = this._element.scrollHeight > document.documentElement.clientHeight

      if (!isModalOverflowing) {
        this._element.style.overflowY = 'hidden'
      }

      this._element.classList.add(CLASS_NAME_STATIC)
      const modalTransitionDuration = getTransitionDurationFromElement(this._dialog)
      EventHandler.off(this._element, TRANSITION_END)
      EventHandler.one(this._element, TRANSITION_END, () => {
        this._element.classList.remove(CLASS_NAME_STATIC)
        if (!isModalOverflowing) {
          EventHandler.one(this._element, TRANSITION_END, () => {
            this._element.style.overflowY = ''
          })
          emulateTransitionEnd(this._element, modalTransitionDuration)
        }
      })
      emulateTransitionEnd(this._element, modalTransitionDuration)
      this._element.focus()
    } else {
      this.hide()
    }
  }

  // Static

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

EventHandler.on(document, EVENT_MOUSEOVER_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  const target = getElementFromSelector(this)

  if (this.tagName === 'A' || this.tagName === 'AREA') {
    event.preventDefault()
  }

  EventHandler.one(target, EVENT_SHOW, showEvent => {
    if (showEvent.defaultPrevented) {
      // only register focus restorer if modal will actually get shown
      return
    }

    EventHandler.one(target, EVENT_HIDDEN, () => {
      if (isVisible(this)) {
        this.focus()
      }
    })
  })
  let data = Data.getData(target, DATA_KEY)
  if (!data) {
    const config = {
      ...Manipulator.getDataAttributes(target),
      ...Manipulator.getDataAttributes(this)
    }

    data = new Menu(target, config)
  }

  data.show(this)

  var page = document.getElementById("page");
  page.addEventListener("mouseenter", function (e) {
    if (document.getElementById("SubNav").classList.contains("open")) {
      document.getElementById("SubNav").classList.remove("open");
    }
  });


})

export default Menu