/**!
 * GAMA v1.0alpha0 (https://holding.gama.com.tr/)
 * Copyright 2020 GAMA Holding A.Ş.
 * UNLICENSED
 */

import {
  getElementFromSelector,
  typeCheckConfig
} from '../dom/util'
import Data from '../dom/data'
import EventHandler from '../dom/event-handler'
import Manipulator from '../dom/manipulator'
import SelectorEngine from '../dom/selector-engine'

const NAME = 'calculator'
const VERSION = '1.0-alpha0'
const DATA_KEY = 'acl.calculator'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  calc: true
}

const DefaultType = {
  calc: '(boolean|string)'
}

const formatter = new Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',
  minimumFractionDigits: 2
});

const EVENT_CALC = `calculate${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`


const SELECTOR_TYPE = '.tipi'
const SELECTOR_AMOUNT = '.tutar'
const SELECTOR_DATA_CALC = '[data-calc="calculator"]'
const SELECTOR_MONTHLY_DISCOUNT = '.MonthDiscount'
const SELECTOR_YEARLY_DISCOUNT = '.YearDiscount'
const SELECTOR_DISCOUNT = '#DiscountResult'
const SELECTOR_HOUSEHOLD_DISCOUNT = '.HouseHoldDiscount'

class Calculator {
  constructor(element, config) {
    this._config = this._getConfig(config)
    this._isShown = false
    this._isTransitioning = false
    this._element = element
    this._type = SelectorEngine.find(SELECTOR_TYPE, element)
    this._amount = SelectorEngine.findOne(SELECTOR_AMOUNT, element)
    this._discount_m = SelectorEngine.findOne(SELECTOR_MONTHLY_DISCOUNT, element)
    this._discount_y = SelectorEngine.findOne(SELECTOR_YEARLY_DISCOUNT, element)
    this._discount_x = SelectorEngine.findOne(SELECTOR_HOUSEHOLD_DISCOUNT, element)
    this._discount = SelectorEngine.findOne(SELECTOR_DISCOUNT, element)
    Data.setData(element, DATA_KEY, this)
  }

  // Getters
  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  // Public
  calculate(relatedTarget) {
    if (this._isShown || this._isTransitioning) {
      return
    }

    var type = SelectorEngine.getValue(this._type)

    var amount = SelectorEngine.getValue(this._amount)

    var multiplier = 0;

    this._discount_m.classList.add("invisible")
    this._discount_y.classList.add("invisible")
    this._discount_x.classList.add("invisible")
    this._discount.classList.add("invisible")

    if (type == 1) { // ticarethane
      multiplier = 7
    }

    if (type == 2) { // sanayi
      multiplier = 4
    }

    this._discount_m.classList.remove("invisible")
    this._discount_y.classList.remove("invisible")
    this._discount_m.parentElement.classList.remove("invisible")
    this._discount_y.parentElement.classList.remove("invisible")
    this._discount_x.classList.add("invisible")

    if (type == 3) { // Mesken
      multiplier = 0
      this._discount_m.parentElement.classList.add("invisible")
      this._discount_y.parentElement.classList.add("invisible")
      this._discount_x.classList.remove("invisible")
    }

    var calculation = {
      "calc_m": (formatter.format((amount * multiplier / 100))),
      "calc_y": (formatter.format((amount * multiplier / 100) * 12))
    }

    const calcEvent = EventHandler.trigger(this._element, EVENT_CALC, {
      relatedTarget
    })

    if (this._isShown || calcEvent.defaultPrevented) {
      return
    }

    SelectorEngine.setValue(this._discount_m, calculation.calc_m)
    SelectorEngine.setValue(this._discount_y, calculation.calc_y)

    this._isShown = true

    this._discount.classList.remove("invisible")



    //this._calcBackdrop(() => this._calcElement(relatedTarget))


    this._isShown = false
  }

  // Private
  _getConfig(config) {
    config = {
      ...Default,
      ...config
    }
    typeCheckConfig(NAME, config, DefaultType)
    return config
  }


}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_CALC, function (event) {
  const target = getElementFromSelector(this)

  if (this.tagName === 'A' || this.tagName === 'AREA') {
    event.preventDefault()
  }

  EventHandler.one(target, EVENT_CALC, calcEvent => {
    if (calcEvent.defaultPrevented) {
      return
    }
  })

  let data = Data.getData(target, DATA_KEY)
  if (!data) {
    const config = {
      ...Manipulator.getDataAttributes(target),
      ...Manipulator.getDataAttributes(this)
    }

    data = new Calculator(target, config)
  }

  data.calculate(this)
  if (window.history.replaceState) {
    window.history.replaceState(null, null, window.location.href);
  }

})

export default Calculator
